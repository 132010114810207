import React, { useState } from 'react'
import useInterval from 'use-interval'

// <!-- const EMOJIS = ["U+1F476",'U+1F9D2', 'U+1F466','U+1F467','U+1F9D1', 'U+1F471', 'U+1F468',
//   'U+1F9D4',['U+1F468','U+200D U+1F9B0'],['U+1F468','U+200D U+1F9B1'], ['U+1F468','U+200D U+1F9B3'], ['U+1F468','U+200D U+1F9B2'],
//   'U+1F469',   ['U+1F469', 'U+200D U+1F9B0'], ['U+1F469','U+200D U+1F9B1'], ['U+1F469','U+200D U+1F9B3'],[' U+1F469','U+200D U+1F9B2'],
//   ['U+1F471','U+200D U+2640 U+FE0F'],['U+1F471','U+200D U+2642 U+FE0F'],'U+1F9D3','U+1F474','U+1F475'];
// const COLORS = ['U+1F3FB', 'U+1F3FC', 'U+1F3FD', 'U+1F3FE', 'U+1F3FF'] -->
const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}
// <!-- const generateEmojis = (n=1000) => {
//   return [...Array(n)].map(() => {
//     const e = EMOJIS[getRandomInt(EMOJIS.length) - 1 ]
//     const c = COLORS[getRandomInt(COLORS.length) - 1 ]
//     console.log(e)
//     console.log(c)
//     let val;
//     if (Array.isArray(e)) {
//       val = [e[0], c, e[1]].join(' ')
//     } else {
//       val = [e, c].join(' ')
//     }
//     console.log(val);
//     return String.fromCodePoint(val);
//   }).join('')
// } -->

const EMOJIS = ['👶🏻','👶🏼','👶🏽','👶🏾','👶🏿','🧒🏻','🧒🏼','🧒🏽','🧒🏾','🧒🏿','👦🏻','👦🏼','👦🏽','👦🏾','👦🏿','👧🏻','👧🏼','👧🏽','👧🏾','👧🏿','🧑🏻','🧑🏼','🧑🏽','🧑🏾','🧑🏿','👱🏻','👱🏼','👱🏽','👱🏾','👱🏿','👨🏻','👨🏼','👨🏽','👨🏾','👨🏿','🧔🏻','🧔🏼','🧔🏽','🧔🏾','🧔🏿','👨🏻‍🦰','👨🏼‍🦰','👨🏽‍🦰','👨🏾‍🦰','👨🏿‍🦰','👨🏻‍🦱','👨🏼‍🦱','👨🏽‍🦱','👨🏾‍🦱','👨🏿‍🦱','👨🏻‍🦳','👨🏼‍🦳','👨🏽‍🦳','👨🏾‍🦳','👨🏿‍🦳','👨🏻‍🦲','👨🏼‍🦲','👨🏽‍🦲','👨🏾‍🦲','👨🏿‍🦲','👩🏻','👩🏼','👩🏽','👩🏾','👩🏿','👩🏻‍🦰','👩🏼‍🦰','👩🏽‍🦰','👩🏾‍🦰','👩🏻‍🦱','👩🏼‍🦱','👩🏽‍🦱','👩🏾‍🦱','👩🏿‍🦱','👩🏻‍🦳','👩🏼‍🦳','👩🏽‍🦳','👩🏾‍🦳','👩🏿‍🦳','👩🏻‍🦲','👩🏼‍🦲','👩🏽‍🦲','👩🏾‍🦲','👩🏿‍🦲','👱🏻‍♀️','👱🏼‍♀️','👱🏽‍♀️','👱🏾‍♀️','👱🏿‍♀️','👱🏻‍♂️','👱🏼‍♂️','👱🏽‍♂️','👱🏾‍♂️','👱🏿‍♂️','🧓🏻','🧓🏼','🧓🏽','🧓🏾','🧓🏿','👴🏻','👴🏼','👴🏽','👴🏾','👴🏿','👵🏻','👵🏼','👵🏽','👵🏾','👵🏿']

const generateEmojis = (n=10000) => {
  return [...Array(n)].map(() => (EMOJIS[getRandomInt(EMOJIS.length) - 1])).join('');
}

const Emojis = () => {
  const [emojis, setEmojis] = useState(generateEmojis())
  const [cycleCount, setCycleCount] = useState(0)
  useInterval(() => {
    setEmojis(generateEmojis())
    setCycleCount(cycleCount + 1)
  }, cycleCount >= 20 ? null : 50 * ((cycleCount + 1)/ 2) ^ 0.2)
  return (<div>
    {emojis}
  </div>);
}

export default Emojis
