import { Link } from 'gatsby';
import Emojis from "../../../src/components/emojis";
import Layout from "../../../src/components/layout";
import Image from "../../../src/components/image";
import Meta from "../../../src/components/meta";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Link,
  Emojis,
  Layout,
  Image,
  Meta,
  React,
  MDXTag
};